@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"







































































.error
  display: flex
  flex-direction: row-reverse
  padding: 144px 72px
  justify-content: space-between

  +mq_l
    flex-direction: row-reverse
    padding: 32px 40px

  +mq_s
    display: block

  &__details
    +mq_s
      text-align: center

    .details__title
      color: $color_main
      max-width: 648px
      padding-bottom: 16px

      +mq_l
        font-size: 20px

    .details__error-type
      color: $color-ink-light
      padding-bottom: 32px

      +mq_l
        font-size: 10px
        padding-bottom: 16px

    .details__comment
      color: $color-ink-light
      max-width: 656px
      padding-bottom: 48px

      +mq_l
        font-size: 14px
        font-weight: 400
        padding-bottom: 32px

  &__ilustration
    +mq_l
      align-self: start
      width: 50%

    +mq_s
      width: 100%
      padding-bottom: 16px
